<template>
  <b-row>
    <b-col sm="12" md="12">
      <my-card :isloading="cardloading" :title="code">
        <template slot="body">
          <form-validation @submit="submitForm">
            <b-row>
              <b-col sm="12" md="7">
                <form-select :disabled="formStatus!=0" :filter-id="idBranchArray" ref="branchSelect" url="v1/branch_select2" :queryparams="{id_regional: mainRegionalId}" @value-changed="branch_name = $event.label;getBalance()" :rules="{required: true}" v-model="id_branch" label="Cabang"></form-select>
              </b-col>
              <b-col sm="12" md="5" class="mt-1 mb-1" v-if="$route.params.id">
                <table class="w-100">
                  <tbody>
                    <tr>
                      <td class="font-weight-bold">Di Input Oleh :</td>
                      <td class="text-right">{{inputInfo.user_create}}</td>
                    </tr>
                    <tr>
                      <td class="font-weight-bold">Di Update Oleh :</td>
                      <td class="text-right">{{inputInfo.user_update}}</td>
                    </tr>
                    <tr>
                      <td class="font-weight-bold">Di Approve Oleh :</td>
                      <td class="text-right">{{inputInfo.user_approval}}</td>
                    </tr>
                  </tbody>
                </table>
              </b-col>
              <b-col sm="12" md="7">
                <form-date :disabled="!cP(114)" @input="getBalance" :rules="{required: true}" v-model="date" label="Tanggal"></form-date>
              </b-col>
              <b-col sm="12">
                <hr>
              </b-col>
              <b-col sm="12">
                <b-row>
                  <b-col sm="3">
                    <small class="font-weight-bold">Saldo Kas</small>
                  </b-col>
                  <b-col sm="4">
                    <form-input-number disabled size="sm" label="" :value="balance"></form-input-number>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col sm="3">
                  </b-col>
                  <b-col sm="4" class="text-center">
                    <h6>Draft</h6>
                    <hr>
                  </b-col>
                  <b-col sm="4" class="text-center" v-if="formStatus==2">
                    <h6>Persetujuan</h6>
                    <hr>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col sm="3">
                    <small class="font-weight-bold">Tambahan Modal</small>
                  </b-col>
                  <b-col sm="4">
                    <form-input-number :disabled="formStatus==2" size="sm" label="" v-model="add_capital"></form-input-number>
                    <hr>
                  </b-col>
                  <b-col sm="4" v-if="formStatus==2">
                    <form-input-number size="sm" label="" v-model="add_capital_approve"></form-input-number>
                    <hr>
                  </b-col>
                </b-row>
                <b-row class="mb-3">
                  <b-col sm="3">
                    <small class="font-weight-bold">Modal</small>
                  </b-col>
                  <b-col sm="4">
                    <form-input-number disabled size="sm" label="" :value="totalModal"></form-input-number>
                  </b-col>
                  <b-col sm="4" v-if="formStatus==2">
                    <form-input-number disabled size="sm" label="" :value="totalModalApprove"></form-input-number>
                  </b-col>
                </b-row>

                <b-row v-for="(tr,i) in details" :key="i">
                  <b-col sm="3">
                    <small class="font-weight-bold">{{tr.payment_partner_name}}</small>
                  </b-col>
                  <b-col sm="4" class="d-flex justify-content-start">
                    <form-input-number class="w-100" :rules="{required: true}" placeholder="Amount" :disabled="formStatus==2" size="sm" label="" v-model="tr.amount"></form-input-number>
                    <form-input class="w-100 ml-1" v-model="tr.note" label="" placeholder="Catatan" size="sm"></form-input>
                  </b-col>
                  <b-col sm="4" v-if="formStatus==2" class="d-flex justify-content-start">
                    <form-input-number class="w-100" :rules="{required: true}" placeholder="Amount" size="sm" label="" v-model="detailsApprove[i].amount"></form-input-number>
                    <form-input class="w-100 ml-1" v-model="detailsApprove[i].note" label="" placeholder="Catatan" size="sm"></form-input>
                  </b-col>
                </b-row>
                <b-row class="mt-3">
                  <b-col sm="3">
                    <small class="font-weight-bold">Total Sales</small>
                  </b-col>
                  <b-col sm="4">
                    <form-input-number disabled size="sm" label="" :value="totalSales"></form-input-number>
                  </b-col>
                  <b-col sm="4" v-if="formStatus==2">
                    <form-input-number disabled size="sm" label="" :value="totalSalesApprove"></form-input-number>
                  </b-col>
                </b-row>
                <b-row class="mb-3">
                  <b-col sm="3">
                    <small class="font-weight-bold">Setoran</small>
                  </b-col>
                  <b-col sm="4">
                    <form-input-number disabled size="sm" label="" :value="totalDeposit"></form-input-number>
                  </b-col>
                  <b-col sm="4" v-if="formStatus==2">
                    <form-input-number disabled size="sm" label="" :value="totalDepositApprove"></form-input-number>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col sm="4">
                    <small>Potongan Setoran</small>
                    <hr>
                  </b-col>
                </b-row>
                <b-row v-for="(tr,i) in reductions" :key="`reductions`+i">
                  <b-col sm="3">
                    <form-select-nested :rules="{required: false}" :disabled="tr.id>0" size="sm" :clearable="false" ref="reductionSelect" url="v1/expense_select2" @value-changed="costChangeRow($event,i)" v-model="tr.id_expense" label=""></form-select-nested>
                  </b-col>
                  <b-col sm="4" class="d-flex justify-content-start">
                    <form-input-number class="w-100" :disabled="formStatus==2" :rules="{required: true}" placeholder="Amount" size="sm" label="" v-model="tr.amount"></form-input-number>
                    <form-input class="w-100 ml-1" v-model="tr.note" label="" placeholder="Catatan" size="sm"></form-input>
                  </b-col>
                  <b-col sm="4" v-if="formStatus==2 && reductionsApprove[i]" class="d-flex justify-content-start">
                    <form-input-number :rules="{required: true}" placeholder="Amount" class="w-100 ml-1" size="sm" label="" v-model="reductionsApprove[i].amount"></form-input-number>
                    <form-input class="w-100 ml-1" v-model="reductionsApprove[i].note" label="" placeholder="Catatan" size="sm"></form-input>
                  </b-col>
                  <b-col sm="1">
                    <b-button v-if="i>0&&!tr.id" size="sm" variant="danger" @click="deleteReduction(i)">
                      x
                    </b-button>
                    <b-button v-if="reductions.length-1==i" size="sm" variant="primary" @click="addReduction">
                      +
                    </b-button>
                  </b-col>
                </b-row>
                <b-row class="mb-3">
                  <b-col sm="3">
                    <hr>
                    <small class="font-weight-bold">Yang Harus Disetorkan</small>
                  </b-col>
                  <b-col sm="4">
                    <hr>
                    <form-input-number disabled size="sm" label="" :value="totalDeposited"></form-input-number>
                  </b-col>
                  <b-col sm="4" v-if="formStatus==2">
                    <hr>
                    <form-input-number disabled size="sm" label="" :value="totalDepositedApprove"></form-input-number>
                  </b-col>
                </b-row>
              </b-col>

              <b-col sm="12">
                <hr>
                <form-textarea :rules="{required: false}" v-model="note" label="Catatan"></form-textarea>
              </b-col>
              <b-col sm="12" class="mt-3">
                <button-save :isloading="isloading" :text="formStatus==2 ? 'Approve':($route.query.quickAction ? 'Simpan dan Lanjutkan':'Simpan')"></button-save>
                <button-back class="ml-1" :to="{name:'operationalins'}"></button-back>
              </b-col>
            </b-row>
          </form-validation>
        </template>
      </my-card>
    </b-col>
  </b-row>
</template>

<script>
import FormTextarea from '@/my-components/FormTextarea.vue'
import FormDate from '@/my-components/FormDate.vue'
import FormInput from '@/my-components/FormInput.vue'
import FormInputNumber from '@/my-components/FormInputNumber.vue'
import FormSelect from '@/my-components/FormSelect.vue'
import FormSelectNested from '@/my-components/FormSelectNested.vue'
import ButtonSave from '@/my-components/ButtonSave.vue'
import ButtonBack from '@/my-components/ButtonBack.vue'
import { mapActions } from 'vuex'
import FormValidation from '@/my-components/FormValidation.vue'
import {BButton} from 'bootstrap-vue'
import _ from 'lodash'
import utils from './utils'

export default {
  components:{
    FormDate,
    ButtonSave,
    ButtonBack,
    FormValidation,
    FormSelect,
    FormInputNumber,
    FormTextarea,
    FormSelectNested,
    BButton,
    FormInput
  },
  data(){
    return {
      isloading: false,
      cardloading: false,
      id: this.$route.params.id||null,
      code: "",
      id_branch: "",
      branch_name: "",
      balance: 0,
      add_capital: 0,
      add_capital_approve: 0,
      note:"",
      date: this.$moment().format(),
      details: [],
      detailsApprove: [],
      reductions: [],
      reductionsApprove: [],
      inputInfo: {}
    }
  },
  computed:{
    formStatus(){
      const meta = this.$route.meta
      if(meta.isEdit) return 1
      else if(meta.isApprove) return 2
      else return 0
    },
    totalModal(){
      return this.balance+this.add_capital
    },
    totalSales(){
      return _.sumBy(this.details,n => (parseFloat(n.amount)))
    },
    totalDeposit(){
      let amount = 0
      for (let i = 0; i < this.details.length; i++) {
        const e = this.details[i];
        if(e?.is_cash) amount+=e.amount
      }
      return amount
    },
    totalReduce(){
      return _.sumBy(this.reductions,n => (parseFloat(n.amount)))
    },
    totalDeposited(){
      return this.totalDeposit-this.totalReduce
    },
    totalModalApprove(){
      return this.balance+this.add_capital_approve
    },
    totalSalesApprove(){
      return _.sumBy(this.detailsApprove,n => (parseFloat(n.amount)))
    },
    totalDepositApprove(){
      let amount = 0
      for (let i = 0; i < this.detailsApprove.length; i++) {
        const e = this.detailsApprove[i];
        if(e?.is_cash) amount+=e.amount
      }
      return amount
    },
    totalReduceApprove(){
      return _.sumBy(this.reductionsApprove,n => (parseFloat(n.amount)))
    },
    totalDepositedApprove(){
      return this.totalDepositApprove-this.totalReduceApprove
    }
  },
  methods:{
    ...mapActions({
      dispatchStore: 'operationalins/store',
      dispatchShow: 'operationalins/show',
      dispatchUpdate: 'operationalins/update',
      dispatchApprove: 'operationalins/approve',
      dispatchPartnerGet: 'partners/getSelect2'
    }),
    async getData(){
      this.cardloading = true
      if(this.formStatus>0){
        Promise.all([
          this.dispatchShow(this.id),
          this.dispatchPartnerGet()
        ]).then(result => {
          const data = result[0]
          const partners = result[1]

          const {user_create,user_update,user_approval} = data
          this.inputInfo = {user_create,user_update,user_approval}

          this.code = data.code
          this.id_branch = data.id_branch
          this.branch_name = data.branch_name
          this.date = data.date
          this.note = data.note
          this.balance = data.balance
          this.add_capital = data.add_capital
          this.add_capital_approve = data.add_capital
          const details = JSON.parse(JSON.stringify(data.operational_in_details))
          const detail_proves = JSON.parse(JSON.stringify(data.operational_in_detail_proves))
          this.details = details.map(d => {
            return Object.assign({},d,{
              is_cash: _.find(partners,{'value':d.id_payment_partner})?.is_cash || 0
            })
          })
          this.detailsApprove = (detail_proves?.length ? detail_proves : details).map(d => {
            return {
              ...d,
              is_cash: _.find(partners,{'value':d.id_payment_partner})?.is_cash || 0,
              id_operational_in_detail: d.id,
              note: d?.note || ''
            }
          })
          const reductionOriginal = data.operational_in_reductions.length>0 ? data.operational_in_reductions : [utils.defaultReductions]
          const reductions = JSON.parse(JSON.stringify(reductionOriginal))
          const reductionsApprove = JSON.parse(JSON.stringify(data.id_status==1?data.operational_in_reductions:data.operational_in_reduce_proves))
          this.reductions = reductions
          this.reductionsApprove = reductionsApprove.map((d,x) => {
            return Object.assign({},d,{
              id_operational_in_reduce: reductions[x] ? reductions[x].id : null,
              note:''
            })
          })
          for (let x = 0; x < reductionsApprove.length; x++) {
            const appr = reductionsApprove[x];
            const det = reductions[x]
            if(!det) this.reductions.push(Object.assign({},appr,{note:'',amount:0}))
          }
          if(this.reductionsApprove.length==0) this.reductionsApprove = [utils.defaultReductions]

          if(data.id_branch) this.$refs.branchSelect.initOption([{value:data.id_branch,label:data.branch_name}])
          for (let x = 0; x < data.operational_in_reductions.length; x++) {
            const e = data.operational_in_reductions[x];
            this.$nextTick(() => {
              this.$refs.reductionSelect[x].initOption([{value:e.id_expense,label:e.expense_name}])
            })
          }
        })
      } else {
        const defaults = this.$store.state.auth
        this.$refs.branchSelect.initOption(defaults.defaultbranches)
        this.id_branch = defaults.profile.id_branch
        this.branch_name = defaults.profile.branch_name
        this.addReduction()

        Promise.all([
          this.dispatchPartnerGet(),
          this.getBalance()
        ]).then(result => {
          const partnerList = result[0]
          let partners = []
          for (let i = 0; i < partnerList.length; i++) {
            const e = partnerList[i];
            partners.push({
              id_payment_partner: e.value,
              payment_partner_name: e.label,
              is_cash: e?.is_cash,
              note:'',
              amount: 0
            })
          }
          this.details = partners
        })
      }
      this.cardloading = false
    },
    async submitForm(){
      this.isloading = true
      const {id,code,id_branch,branch_name,date,note,details,reductions,balance,add_capital} = this
      let params = {id,code,id_branch,branch_name,date,note,details,balance,add_capital}
      params.total_sale = this.totalSales
      params.total_deposit = this.totalDeposit
      params.total_reduce = this.totalReduce
      params.total_deposited = this.totalDeposited
      params.reductions = reductions.filter(d => d.amount>0)
      if(this.formStatus==2){
        params.details = this.detailsApprove
        let reductions = JSON.parse(JSON.stringify(this.reductionsApprove))
        for (let x = 0; x < reductions.length; x++) {
          delete reductions[x].id
        }
        // params.reductions = reductions.filter(d => d.amount>0)
        params.add_capital_proved = this.add_capital_approve
        params.total_deposit_proved = this.totalDepositApprove
        params.total_sale_proved = this.totalSalesApprove
        params.total_reduce_proved = this.totalReduceApprove
        params.total_deposited_proved = this.totalDepositedApprove
        delete params.id_branch
        delete params.branch_name
        delete params.add_capital
        delete params.total_sale
        delete params.total_deposit
        delete params.total_reduce
        delete params.total_deposited
      }
      try {
        // console.log(params)
        if (this.formStatus==1) await this.dispatchUpdate(params);
        else if (this.formStatus==2) await this.dispatchApprove(params);
        else await this.dispatchStore(params);
        this.toastSuccess("OK","Data berhasil disimpan!")
        if(this.$route.query.quickAction) this.$router.push({name:'operationalouts-add', query:{quickAction: true}})
        else this.$router.push({name:'operationalins'})
      } catch (error) {
        this.handleError(error)
      }
      this.isloading = false
    },
    addReduction(){
      this.reductions.push({
        "id_expense" : null,
        "expense_name" : "",
        "note": "",
        "amount": 0
      })
      if(this.formStatus==2){
        this.reductionsApprove.push({
          "id_expense" : null,
          "expense_name" : "",
          "note": "",
          "amount": 0,
        })
      }
    },
    costChangeRow(e,row){
      this.details[row].expense_name = e.label
      const indexAppr = this.reductionsApprove[row]
      if(typeof(indexAppr)!=='undefined') {
        this.reductionsApprove[row].id_expense = e.value
        this.reductionsApprove[row].expense_name = e.label
      }
    },
    deleteReduction(row){
      this.reductions.splice(row, 1)
      if(this.formStatus==2) this.reductionsApprove.splice(row, 1)
    },
    async getBalance(){
      const {id_branch, date} = this
      try {
        const { data } = await this.$http.get(`v1/operational_balance_get`,{params:{id_branch,date}})
        this.balance = data.balance.balance
        return Promise.resolve(data)
      } catch (error) {
        return Promise.reject(error)
      }
    },
  },
  mounted(){
    this.getData()
  }
}
</script>

<style>

</style>